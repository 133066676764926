import axios from 'axios';

const createAxiosInstance = (baseUrl) => {

  const instance = axios.create({
    baseURL: baseUrl ? baseUrl : `http://18.207.126.139/api/v1`,
    timeout: 500000,
  });

  const token = ''
  instance.defaults.headers.common['Authorization'] = `Bearer ${token}`;

  return instance;
};


export default createAxiosInstance;
