import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import BadgeIcon from '@mui/icons-material/Badge';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import DomainIcon from '@mui/icons-material/Domain';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FolderCopyIcon from '@mui/icons-material/FolderCopy';
import InfoIcon from '@mui/icons-material/Info';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import LayersIcon from '@mui/icons-material/Layers';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import NumbersIcon from '@mui/icons-material/Numbers';
import QueryBuilderIcon from '@mui/icons-material/QueryBuilder';
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Grid, List, ListItem, ListItemButton, ListItemText, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, ToggleButton, ToggleButtonGroup, Tooltip, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { DaysData } from './data/courseSearchData';
import { principalTheme } from '../theme/principalTheme';

const primaryColor = principalTheme.palette.primary.main;;
const colorText = '#374151';

const mainActivityColor = '#3DC7C7';
const complementaryActivityColor = '#23A6F0';

export default function CourseDetails ()
{

  const navigate = useNavigate();
  const { state } = useLocation();

  const [ course, setCourse ] = useState(undefined);

  const [ activities, setActivities] = useState([]);
  const [ activity, setActivity ] = useState(undefined);

  const [ groups, setGroups ] = useState([]);
  const [ group, setGroup ] = useState( undefined );

  const [detailData, setDetailData] = useState(undefined);
  const [parentGroupData, setParentGroupData] = useState(undefined);

  const [timeZones, setTimeZones] = useState([]);

  useEffect(() => {
    if (state && state.course) {
      const course = state.course;
      setCourse(state.course);
      if (state.course.DETALLECURSOASIGNATURA && state.course.DETALLECURSOASIGNATURA.length) {
        const activities = getActivities(course);
        setActivities(activities);
        const foundActivity = activities[0];
        setActivity(foundActivity.id);
        handleGroupFilteredByActivity(course, foundActivity.id);
        const foundTimeZones = getUniqueTimeZones(state.course.DETALLECURSOASIGNATURA);
        setTimeZones(foundTimeZones);
      }
    } else {
      navigate('/');
    }
  }, []);


  const getActivities = (course) => {
    const activitiesData = course.DETALLECURSOASIGNATURA.map(detail => {
      return {
        id: detail.ID_ACTIV,
        activity: detail.ACTIVIDAD,
        parentActivity: detail.ACTIVIDAD_PADRE,
      }
    });
    const uniqueIds = {};
    const result = activitiesData.filter(activity => {
      if (!uniqueIds[activity.id]) {
        uniqueIds[activity.id] = true;
        return true;
      }
      return false;
    }).sort((a, b) => a.parentActivity - b.parentActivity);
    return result;
  }

  const handleGroupFilteredByActivity = (course, activityId) => {
    const groupsFilteredByActivity = course.DETALLECURSOASIGNATURA.filter(detail => detail.ID_ACTIV === activityId).sort((a, b) => a.GRUPO - b.GRUPO);
    const foundGroup = groupsFilteredByActivity[0];
    setGroups(groupsFilteredByActivity);
    setGroup(foundGroup.ID);
    handleSetParentGroupData(foundGroup);
    setDetailData(foundGroup);
  }

  const getUniqueTimeZones = (data) => {
    const allTimes = [];
    const dayKeys = [
      'HORARIO_LUNES', 'HORARIO_MARTES', 'HORARIO_MIERCOLES', 
      'HORARIO_JUEVES', 'HORARIO_VIERNES', 'HORARIO_SABADO', 
      'HORARIO_DOMINGO'
    ];

    data.forEach(item => {
      dayKeys.forEach(key => {
        if (item[key] && item[key] !== "NULL") {
          allTimes.push(...item[key].split('|'));
        }
      });
    });

    // Eliminar duplicados
    const uniqueTimes = [...new Set(allTimes)];

    // Convertir a un formato de 24 horas para facilitar la comparación
    const convertTo24HourFormat = (time) => {
      const [hours, minutes] = time.trim().split(':');
      return parseInt(hours) * 60 + parseInt(minutes);
    };

    // Ordenar las horas
    uniqueTimes.sort((a, b) => convertTo24HourFormat(a) - convertTo24HourFormat(b));

    return uniqueTimes;
  };

  const handleChange = (
    event, newValue,
  ) =>
  {
    if (newValue !== null && group !== newValue) {
      setGroup( newValue );
      const detail = course.DETALLECURSOASIGNATURA.find((detail ) => detail.ID === newValue);
      // console.log('detailSecondary', detail);
      handleSetParentGroupData(detail);
      setDetailData(detail);
    }
  };

  const handleSetParentGroupData = (activitySecondary) => {
    if (activitySecondary.ACTIVIDAD_PADRE) {
      const parentGroupFound = course.DETALLECURSOASIGNATURA.find((found) => !found.ACTIVIDAD_PADRE && found.ID_ACTIV === activitySecondary.ACTIVIDAD_PADRE && found.GRUPO === activitySecondary.GRUPO_PADRE);
      // console.log('parentGroupFound', parentGroupFound);
      setParentGroupData(parentGroupFound);
    } else {
      setParentGroupData(undefined);
    }
  }

  const handleChangeActivity = (event, newValue) => {
    if (newValue !== null && activity !== newValue) {
      setActivity(newValue);
      handleGroupFilteredByActivity(course, newValue);
    }
  };

  const validateTimeZone = (timeZone, schedule) => {

    if (timeZone && schedule) {

      const [startTime, endTime] = schedule.split('|');
      const [hours, minutes] = timeZone.split(':');
  
      const currentDate = new Date();
      const startTimeDate = new Date(
        currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate(),
        startTime.split(':')[0], startTime.split(':')[1]
      );
      const endTimeDate = new Date(
        currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate(),
        endTime.split(':')[0], endTime.split(':')[1]
      );
      const inputTimeDate = new Date(
        currentDate.getFullYear(), currentDate.getMonth(),
        currentDate.getDate(), hours, minutes
      );
  
      const isValid = inputTimeDate >= startTimeDate && inputTimeDate <= endTimeDate;
      return isValid;
    }
    return false;
  }

  const retrieveTimeZoneInfo = (day, timeZone) => {

    let formattedDay = day.toUpperCase();

    if (day === 'Miércoles') {
      formattedDay = 'MIERCOLES';
    }

    if (day === 'Sábado') {
      formattedDay = 'SABADO';
    }

    const detail = course.DETALLECURSOASIGNATURA.find((detail ) => detail.ID === group);
    // setDetailData(detail);
    const schedule = detail['HORARIO_'+formattedDay];

    if (schedule && schedule !== 'NULL') {
      const fistSchedule = schedule.split('|')[0].trim();
      return {
        isFirstSchedule: fistSchedule === timeZone,
        hasTimeZone: validateTimeZone(timeZone, schedule),
        detailCursoAsignatura: detail,
        schedule,
      }
    }
    return { hasTimeZone: false }
  }

  const retrieveTimeZoneInfoGroupParent = (day, timeZone) => {

    let formattedDay = day.toUpperCase();

    if (day === 'Miércoles') {
      formattedDay = 'MIERCOLES';
    }

    if (day === 'Sábado') {
      formattedDay = 'SABADO';
    }

    const detail = parentGroupData;
    const schedule = detail && detail['HORARIO_'+formattedDay];

    if (schedule && schedule !== 'NULL') {
      const fistSchedule = schedule.split('|')[0].trim();
      return {
        isFirstSchedule: fistSchedule === timeZone,
        hasTimeZone: validateTimeZone(timeZone, schedule),
        detailCursoAsignatura: detail,
        schedule
      }
    }
    return { hasTimeZone: false }
  }

  const handleBackClick = () => {
    navigate(-1);
  };

  return (
    <>
      { course &&
        <Box>
          <Button sx={{ color: primaryColor, paddingLeft: 0, '.MuiSvgIcon-root': {fontSize: '36px !important'} }}onClick={handleBackClick} startIcon={<KeyboardArrowLeftIcon />} size="large">
            Volver
          </Button>
          <Typography variant='h3' gutterBottom component={ 'h2' } sx={ { fontWeight: '700', color: primaryColor, mt: 4 } }>
            {course.NOMBREASIGNATURA}
          </Typography>
          <br></br>
          <Grid container spacing={ 4 } sx={ { mb: 6 } }>
            <Grid item xs={ 12 } sm={ 6 } md={ 3 }>
              <Box display='flex' gap='12px' alignItems='center'>
                <FolderCopyIcon sx={ { color: primaryColor, fontSize: '28px' } }></FolderCopyIcon>
                <Box display='flex' gap='4px' >
                  <Typography variant='body1' sx={ { fontWeight: '700', color: colorText } }>
                    Código:
                  </Typography>
                  <Typography variant='body1' sx={ { color: colorText } }>
                    {course.CODIGO_ASIGNATURA}
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={ 12 } sm={ 6 } md={ 3 }>
              <Box display='flex' gap='12px' alignItems='center'>
                <DomainIcon sx={ { color: primaryColor, fontSize: '28px' } }></DomainIcon>
                <Box display='flex' gap='4px' >
                  <Typography variant='body1' sx={ { fontWeight: '700', color: colorText } }>
                    Facultad:
                  </Typography>
                  <Typography variant='body1' sx={ { color: colorText } }>
                    {course.FACULTAD}
                  </Typography>
                </Box>
              </Box>
            </Grid>

            <Grid item xs={ 12 } sm={ 6 } md={ 3 }>
              <Box display='flex' gap='12px' alignItems='center'>
                <LocationOnIcon sx={ { color: primaryColor, fontSize: '28px' } }></LocationOnIcon>
                <Box display='flex' gap='4px' >
                  <Typography variant='body1' sx={ { fontWeight: '700', color: colorText } }>
                    Sede :
                  </Typography>
                  <Typography variant='body1' sx={ { color: colorText } }>
                    {course.SEDE}
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={ 12 } sm={ 6 } md={ 3 }>
              <Box display='flex' gap='12px' alignItems='center'>
                <BadgeIcon sx={ { color: primaryColor, fontSize: '28px' } }></BadgeIcon>
                <Box display='flex' gap='4px' >
                  <Typography variant='body1' sx={ { fontWeight: '700', color: colorText } }>
                    Unidad académica básica:
                  </Typography>
                  <Typography variant='body1' sx={ { color: colorText } }>
                    {course.UAB}
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={ 12 } sm={ 6 } md={ 3 }>
              <Box display='flex' gap='12px' alignItems='center'>
                <LayersIcon sx={ { color: primaryColor, fontSize: '28px' } }></LayersIcon>
                <Box display='flex' gap='4px' >
                  <Typography variant='body1' sx={ { fontWeight: '700', color: colorText } }>
                    Nivel:
                  </Typography>
                  <Typography variant='body1' sx={ { color: colorText } }>
                    {course.NIVELDEESTUDIO}
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={ 12 } sm={ 6 } md={ 3 }>
              <Box display='flex' gap='12px' alignItems='center'>
                <LayersIcon sx={ { color: primaryColor, fontSize: '28px' } }></LayersIcon>
                <Box display='flex' gap='4px' >
                  <Typography variant='body1' sx={ { fontWeight: '700', color: colorText } }>
                    Porcentaje de asistencia:
                  </Typography>
                  <Typography variant='body1' sx={ { color: colorText } }>
                    {course.PORCENTAJE_ASISTENCIA}%
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={ 12 } sm={ 6 } md={ 3 }>
              <Box display='flex' gap='12px' alignItems='center'>
                <LibraryBooksIcon sx={ { color: primaryColor, fontSize: '28px' } }></LibraryBooksIcon>
                <Box display='flex' gap='4px' >
                  <Typography variant='body1' sx={ { fontWeight: '700', color: colorText } }>
                    Asignatura libre elección:
                  </Typography>
                  <Typography variant='body1' sx={ { color: colorText } }>
                    {course.ASIGNATURALIBREELECCION ? 'Si' : 'No'}
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={ 12 } sm={ 6 } md={ 3 }>
              <Box display='flex' gap='12px' alignItems='center'>
                <QueryBuilderIcon sx={ { color: primaryColor, fontSize: '28px' } }></QueryBuilderIcon>
                <Box display='flex' gap='4px' >
                  <Typography variant='body1' sx={ { fontWeight: '700', color: colorText } }>
                    Minimo horas:
                  </Typography>
                  <Typography variant='body1' sx={ { color: colorText } }>
                    {course.MINIMOHORAS}
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={ 12 } sm={ 6 } md={ 3 }>
              <Box display='flex' gap='12px' alignItems='center'>
                <NumbersIcon sx={ { color: primaryColor, fontSize: '28px' } }></NumbersIcon>
                <Box display='flex' gap='4px' >
                  <Typography variant='body1' sx={ { fontWeight: '700', color: colorText } }>
                    Número de creditos:
                  </Typography>
                  <Typography variant='body1' sx={ { color: colorText } }>
                    {course.NUM_CREDITOS}
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={ 12 } sm={ 6 } md={ 3 }>
              <Box display='flex' gap='12px' alignItems='center'>
                <CalendarMonthIcon sx={ { color: primaryColor, fontSize: '28px' } }></CalendarMonthIcon>
                <Box display='flex' gap='4px' >
                  <Typography variant='body1' sx={ { fontWeight: '700', color: colorText } }>
                    Número de semanas:
                  </Typography>
                  <Typography variant='body1' sx={ { color: colorText } }>
                    {course.NUM_SEMANAS}
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={ 12 } sm={ 6 } md={ 3 }>
              <Box display='flex' gap='12px' alignItems='center'>
                <AssignmentTurnedInIcon sx={ { color: primaryColor, fontSize: '28px' } }></AssignmentTurnedInIcon>
                <Box display='flex' gap='4px' >
                  <Typography variant='body1' sx={ { fontWeight: '700', color: colorText } }>
                    Obligatoria:
                  </Typography>
                  <Typography variant='body1' sx={ { color: colorText } }>
                    {course.OBLIGATORIA ? 'Si' : 'No'}
                  </Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>


          { course.DETALLECURSOASIGNATURA && course.DETALLECURSOASIGNATURA.length > 0 && timeZones.length > 0 &&
            <>
              <Typography variant='h5' gutterBottom component={ 'h3' } sx={ { color: primaryColor } }>
                Programación asignatura
              </Typography>
              <Box>
              <Typography variant='h6' gutterBottom component={ 'h4' } sx={ { color: colorText } }>
                Actividades
              </Typography>

                <ToggleButtonGroup
                  color='primary'
                  value={ activity }
                  exclusive
                  onChange={ handleChangeActivity }
                  aria-label='Actividades'
                  sx={{
                    mb: 4,
                    '.MuiButtonBase-root': {
                      '&.Mui-selected, &.Mui-selected:hover': {
                        background: complementaryActivityColor,
                        '.MuiTypography-root': {
                          color: '#fff',
                        }
                      },
                      '.MuiTypography-root': {
                        color: complementaryActivityColor,
                        fontWeight: 700
                      },
                    },
                    '.MuiButtonBase-root.main-activity': {
                      '&.Mui-selected, &.Mui-selected:hover': {
                        background: mainActivityColor,
                        '.MuiTypography-root': {
                          color: '#fff',
                        }
                      },
                      '.MuiTypography-root': {
                        color: mainActivityColor,
                      }
                    }
                  }}
                >
                  {activities.map((activity, index) => (
                    <ToggleButton value={ activity.id } disableRipple key={index} className={ !activity.parentActivity ? 'main-activity' : ''}>
                      <Typography variant='body2' sx={ { display: 'block', color: colorText } } component='span'>
                        {activity.activity} - {activity.id}
                      </Typography>
                    </ToggleButton>
                  ))}
                </ToggleButtonGroup>

                <Typography variant='h6' gutterBottom component={ 'h4' } sx={ { color: colorText } }>
                  Grupos
                </Typography>
                <ToggleButtonGroup
                  color='primary'
                  value={ group }
                  exclusive
                  onChange={ handleChange }
                  aria-label='Lista de grupos'
                  sx={ {
                    gap: '22px',
                    overflowX: 'auto',
                    width: '100%',
                    '.MuiToggleButtonGroup-grouped': {
                      flexDirection: 'column',
                      alignItems: 'flex-start',
                      textTransform: 'capitalize',
                      minWidth: '210px',
                      border: 0,
                      background: '#E5F5FF',
                      borderRadius: 0,
                      transition: 'none',
                      '&.Mui-selected, &:hover': {
                        background: '#7EC4F6',
                        '&::after': {
                          borderColor: 'transparent transparent transparent #7EC4F6',
                        },
                        '.MuiTypography-body2': {
                          color: '#fff',
                        },
                        '&:hover': { background: '#7EC4F6' }
                      },
                    }
                  } }
                >
                  {groups.map((group, index) => (
                    <ToggleButton value={ group.ID } disableRipple key={index}>
                      <Typography variant='body2' sx={ { display: 'block', color: colorText, textTransform: 'initial' } } component='span'>{group.GRUPO}</Typography>
                      <Typography variant='body2' sx={ { color: colorText, textTransform: 'initial' } } component='span'>Cupos disponibles: {group.CUPOS_DISPONIBLES ?? '-'}</Typography>
                      <Typography variant='body2' sx={ { color: colorText } } component='span'>Cupos: {group.CUPOS ?? '-'}</Typography>
                    </ToggleButton>
                  ))}
                </ToggleButtonGroup>

                {activities.some(activity => !activity.parentActivity) && activities.some(activity => activity.parentActivity) &&
                  <Box sx={{
                    mt: 2,
                    '.legend-item': {
                      display: 'flex',
                      alignItems: 'center',
                      gap: '8px'
                    },
                    '.legend-item_character': {
                      width: '20px',
                      height: '20px',
                      display: 'inline-flex',
                      background: complementaryActivityColor,
                    },
                    '.legend-item_character--main-activity': {
                      background: mainActivityColor,
                    }
                  }}>
                    <Typography variant="overline" gutterBottom className='legend-item'>
                      <span className="legend-item_character legend-item_character--main-activity"></span>Actividad princial - <b>{activities[0].activity}</b>
                    </Typography>
                    <Typography variant="overline" gutterBottom className='legend-item'>
                      <span className="legend-item_character"></span> Actividad Complementaria
                    </Typography>
                  </Box>
                }
              </Box>
              <Grid container spacing={ 4 } sx={ { mb: 6 } }>
                <Grid item xs={ 12 } md={ detailData.PLANES_ASOCIADOS ? 10 : 12 }>
                  { group &&
                    <Box>
                      <TableContainer component={ Paper }
                        sx={ {
                          mt: 3,
                          overflowX: 'auto',
                          boxShadow: 'none'
                        } }>
                        <Table sx={ { minWidth: 650 } } size='small' aria-label='Horario programación de asignatura'>
                          <TableHead sx={ {
                            '.MuiTableCell-root': {
                              background: '#7F84DC',
                              color: '#fff',
                              border: '1px solid #DFE0F6',
                              fontSize: '22px',
                              fontWeight: '700',
                              minWidth: '140px',
                              '&:first-child': {
                                background: 'transparent',
                                border: 0,
                              },
                            }
                          } }>
                            <TableRow>
                              <TableCell align='center'></TableCell>
                              {
                                DaysData.map((day) => (
                                  <TableCell align='center' key={day}>{day}</TableCell>
                              ))}
                            </TableRow>
                          </TableHead>
                          <TableBody
                            sx={ {
                            'th': {
                              background: '#7F84DC',
                              color: '#fff',
                              fontSize: '22px',
                              fontWeight: '500'
                            },
                            'td': {
                              padding: 0,
                              position: 'relative',
                            },
                            '.groups': {
                              display: 'flex',
                              position: 'absolute',
                              top: -1,
                              left: 0,
                              width: '100%',
                              height: '45px',
                            },
                            '.groups-multiple': {
                              '.group': {
                                '.MuiButton-root': {
                                  display: 'flex',
                                  padding: 0,
                                  color: '#fff',
                                  fontSize: 10,
                                  textTransform: 'inherit',
                                  '.MuiSvgIcon-root': {fontSize: '14px !important'},
                                },
                              },
                              '.group_wrap': {
                                width: '50%'
                              }
                            },
                            '.group': {
                              background: complementaryActivityColor,
                              color: '#fff',
                              padding: '5px',
                              width: '100%',
                              display: 'inline-block',
                              lineHeight: 1,
                              fontWeight: 600,
                              // boxShadow: '3px 0 3px -3px rgba(0, 0, 0, 0.6)',
                              '.group_wrap': {
                                position: 'absolute',
                                top: '4px',
                                left: 0,
                                overflowY: 'auto',
                                height: '84px',
                                'z-index': 1,
                                padding: '0 4px',
                              },
                              '.teacher, .place, .activity, .group_label, .time-zone': {
                                display: 'block',
                                fontSize: 10,
                                fontWeight: 400,
                                marginBottom: '4px',
                              },
                              '.activity': {
                                fontSize: 10,
                              }
                            },
                            '.group.group-parent': {
                              background: mainActivityColor,
                              '.group_wrap': {
                              }
                            },
                            '.MuiTableCell-body.MuiTableCell-root': {
                              border: '1px solid #DFE0F6',
                            }
                          } }>
                            { timeZones.map((timeZone) => (
                              <TableRow
                                key={timeZone}
                                sx={ { '&:last-child td, &:last-child th': { border: 0 } } }
                              >
                                <TableCell component='th' scope='row' align='center'>
                                  {timeZone}
                                </TableCell>
                                {
                                DaysData.map((day) => (
                                  <TableCell key={timeZone+day}>
                                    <div className={
                                        retrieveTimeZoneInfo(day, timeZone).hasTimeZone &&
                                        retrieveTimeZoneInfoGroupParent(day, timeZone).hasTimeZone ? 'groups groups-multiple': 'groups'}>
                                      {/* Horario secundario */}
                                      { retrieveTimeZoneInfo(day, timeZone).hasTimeZone &&
                                        <span className={groups.every(group => !group.ACTIVIDAD_PADRE) ? 'group group-parent main-activity': 'group'}>
                                          { retrieveTimeZoneInfo(day, timeZone).isFirstSchedule &&
                                            <>
                                              {retrieveTimeZoneInfo(day, timeZone).hasTimeZone &&
                                               retrieveTimeZoneInfoGroupParent(day, timeZone).hasTimeZone ? (

                                                <Tooltip
                                                    title={
                                                      <React.Fragment>
                                                        <Box className='popover_wrap'>
                                                          {retrieveTimeZoneInfoGroupParent(day, timeZone).hasTimeZone &&
                                                            <Box>
                                                              <span className="activity">Grupo principal: {retrieveTimeZoneInfoGroupParent(day, timeZone).detailCursoAsignatura.GRUPO}</span>
                                                              <br></br>
                                                              <span className="activity">Actividad principal: {retrieveTimeZoneInfoGroupParent(day, timeZone).detailCursoAsignatura.ACTIVIDAD}</span>
                                                              <br></br>
                                                              <span className='time-zone'>Horario principal: {retrieveTimeZoneInfoGroupParent(day, timeZone).schedule}</span>
                                                              <br></br><br></br>
                                                            </Box>
                                                          }
                                                          <span className='time-zone'>Horario: {retrieveTimeZoneInfo(day, timeZone).schedule}</span>
                                                          <br></br>
                                                          <span className='teacher'>Docente: {
                                                            retrieveTimeZoneInfo(day, timeZone).detailCursoAsignatura.DOCENTE &&
                                                            retrieveTimeZoneInfo(day, timeZone).detailCursoAsignatura.DOCENTE !== 'NULL' ?
                                                              retrieveTimeZoneInfo(day, timeZone).detailCursoAsignatura.DOCENTE :
                                                              'No asignado'}</span>
                                                          <br></br>
                                                          <span className='place'>Lugar: {
                                                            retrieveTimeZoneInfo(day, timeZone).detailCursoAsignatura.AULA &&
                                                            retrieveTimeZoneInfo(day, timeZone).detailCursoAsignatura.AULA !== 'NULL' ?
                                                            retrieveTimeZoneInfo(day, timeZone).detailCursoAsignatura.AULA :
                                                            'No asignado'}</span>
                                                          <br></br>
                                                          <span className='place'>Periodo: {
                                                            retrieveTimeZoneInfo(day, timeZone).detailCursoAsignatura.PERIODO &&
                                                            retrieveTimeZoneInfo(day, timeZone).detailCursoAsignatura.PERIODO !== 'NULL' ?
                                                            retrieveTimeZoneInfo(day, timeZone).detailCursoAsignatura.PERIODO :
                                                            'No asignado'}</span>
                                                        </Box>
                                                      </React.Fragment>
                                                    }
                                                    arrow
                                                  >
                                                  <span className='group_wrap'>
                                                    {retrieveTimeZoneInfoGroupParent(day, timeZone).hasTimeZone &&
                                                      <>
                                                        <span className="group_label">Grupo principal: {retrieveTimeZoneInfoGroupParent(day, timeZone).detailCursoAsignatura.GRUPO}</span>
                                                      </>
                                                    }
                                                    <Button>Ver detalle<InfoIcon fontSize="small" /></Button>
                                                  </span>
                                                </Tooltip>
                                               ):
                                               (
                                                <span className='group_wrap'>
                                                  <span className='teacher'>Docente: {
                                                    retrieveTimeZoneInfo(day, timeZone).detailCursoAsignatura.DOCENTE &&
                                                    retrieveTimeZoneInfo(day, timeZone).detailCursoAsignatura.DOCENTE !== 'NULL' ?
                                                      retrieveTimeZoneInfo(day, timeZone).detailCursoAsignatura.DOCENTE :
                                                      'No asignado'}</span>
                                                  <span className='place'>Lugar: {
                                                    retrieveTimeZoneInfo(day, timeZone).detailCursoAsignatura.AULA &&
                                                    retrieveTimeZoneInfo(day, timeZone).detailCursoAsignatura.AULA !== 'NULL' ?
                                                    retrieveTimeZoneInfo(day, timeZone).detailCursoAsignatura.AULA :
                                                    'No asignado'}</span>
                                                </span>
                                               )
                                              }
                                            </>
                                          }
                                        </span>
                                      }
                                      {/* Horario principal */}
                                      { retrieveTimeZoneInfoGroupParent(day, timeZone).hasTimeZone &&
                                        <span className='group group-parent'>
                                          { retrieveTimeZoneInfoGroupParent(day, timeZone).isFirstSchedule &&
                                            <>
                                              {(retrieveTimeZoneInfo(day, timeZone).hasTimeZone &&
                                                retrieveTimeZoneInfoGroupParent(day, timeZone).hasTimeZone) ? (
                                                <Tooltip
                                                    title={
                                                      <React.Fragment>
                                                        <span className='popover_wrap'>
                                                          <span className='time-zone'>Horario: {retrieveTimeZoneInfoGroupParent(day, timeZone).schedule}</span>
                                                          <br></br>
                                                          <span className='teacher'>Docente: {
                                                            retrieveTimeZoneInfoGroupParent(day, timeZone).detailCursoAsignatura.DOCENTE &&
                                                            retrieveTimeZoneInfoGroupParent(day, timeZone).detailCursoAsignatura.DOCENTE !== 'NULL' ?
                                                              retrieveTimeZoneInfoGroupParent(day, timeZone).detailCursoAsignatura.DOCENTE :
                                                              'No asignado'}</span>
                                                          <br></br>
                                                          <span className='place'>Lugar: {
                                                            retrieveTimeZoneInfoGroupParent(day, timeZone).detailCursoAsignatura.AULA &&
                                                            retrieveTimeZoneInfoGroupParent(day, timeZone).detailCursoAsignatura.AULA !== 'NULL' ?
                                                            retrieveTimeZoneInfoGroupParent(day, timeZone).detailCursoAsignatura.AULA :
                                                            'No asignado'}</span>
                                                          <br></br>
                                                          <span className='place'>Periodo: {
                                                            retrieveTimeZoneInfoGroupParent(day, timeZone).detailCursoAsignatura.PERIODO &&
                                                            retrieveTimeZoneInfoGroupParent(day, timeZone).detailCursoAsignatura.PERIODO !== 'NULL' ?
                                                            retrieveTimeZoneInfoGroupParent(day, timeZone).detailCursoAsignatura.PERIODO :
                                                            'No asignado'}</span>
                                                        </span>
                                                      </React.Fragment>
                                                    }
                                                    arrow
                                                  >
                                                    <Button>Ver detalle<InfoIcon fontSize="small" /></Button>
                                                  </Tooltip>
                                                ): (
                                                  <span className='group_wrap'>
                                                    <span className='teacher'>Docente: {
                                                      retrieveTimeZoneInfoGroupParent(day, timeZone).detailCursoAsignatura.DOCENTE &&
                                                      retrieveTimeZoneInfoGroupParent(day, timeZone).detailCursoAsignatura.DOCENTE !== 'NULL' ?
                                                        retrieveTimeZoneInfoGroupParent(day, timeZone).detailCursoAsignatura.DOCENTE :
                                                        'No asignado'}</span>
                                                    <span className='place'>Lugar: {
                                                      retrieveTimeZoneInfoGroupParent(day, timeZone).detailCursoAsignatura.AULA &&
                                                      retrieveTimeZoneInfoGroupParent(day, timeZone).detailCursoAsignatura.AULA !== 'NULL' ?
                                                      retrieveTimeZoneInfoGroupParent(day, timeZone).detailCursoAsignatura.AULA :
                                                      'No asignado'}</span>
                                                  </span>
                                                )
                                              }
                                            </>
                                          }
                                        </span>
                                      }
                                    </div>
                                  </TableCell>
                                ))}
                              </TableRow>
                            ))} 

                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Box>
                  }
                </Grid>
                { detailData.PLANES_ASOCIADOS &&
                  <Grid item xs={ 12 } md={ 2 }>
                    <List dense={ true } sx={ {
                        mt: 2,
                        '.MuiListItem-root': {
                          background: '#f7f7f7',
                        },
                        '.MuiListItem-root, .MuiListItemButton-root': {
                          padding: '10px 20px 10px 20px',
                          border: '1px solid #DFDFDF',
                          borderBottomWidth: 0,
                          color: colorText,
                        },
                        '.MuiListItemButton-root:last-child': {
                          borderBottomWidth: '1px',
                        }
                      } }>
                        {detailData.TIP_LIMITE ? (
                          <ListItem>
                            {(detailData.TIP_LIMITE === 'Afirmacion' || detailData.TIP_LIMITE === 'Afirmación') &&
                              <ListItemText
                                primary='Planes de estudios asociados'
                              />
                            }
                            {(detailData.TIP_LIMITE === 'Negacion' || detailData.TIP_LIMITE === 'Negación') &&
                              <ListItemText
                                primary='Inscripción no permitida para estos planes'
                              />
                            }
                          </ListItem>
                        ): (
                          <ListItem>
                            <ListItemText
                              primary='Planes asociados'
                            />
                          </ListItem>
                        )}
                        { detailData.PLANES_ASOCIADOS.split('*** ').filter(item => item.trim() !== '').map((plan, index) => (
                        <ListItemButton key={index}>
                          <ListItemText
                            primary={ plan }
                          />
                        </ListItemButton>
                        ))}
                      </List>
                  </Grid>
                }
              </Grid>
            </>
          }
          <Grid container spacing={ 2 } sx={ { mb: 6 } }>











          <Grid item xs={12}>
  <Accordion>
    <AccordionSummary
      expandIcon={<ExpandMoreIcon />}
      aria-controls="bibliography-content"
      id="bibliography-header"
      sx={{ fontSize: '20px', color: primaryColor, fontWeight: '700' }}
    >
      Bibliografía
    </AccordionSummary>
    <AccordionDetails>
      {course.BIBLIOGRAFIA ? (() => {
        try {
          // Quitar espacios y parsear como string de lista
          const raw = `[${course.BIBLIOGRAFIA}]`.replace(/\s*,\s*/g, ',');
          const parsedList = JSON.parse(raw); // ahora es un array de strings

          return parsedList.map((item, index) => {
            try {
              const subList = JSON.parse(item); // esto sí es un array de objetos
              return subList.map((bibliografia, subIndex) => (
                <Box key={`${index}-${subIndex}`} sx={{ mb: 3 }}>
                  <Typography variant="h6" component="div"><strong>Bibliografía {index + 1}</strong></Typography>
                  <List dense={true}>
                    <ListItem>
                      <ListItemText>
                        <Typography variant="body1" color="text.secondary"><strong>Tipo: </strong> {bibliografia.tipoBibliografia || '-'}</Typography>
                      </ListItemText>
                    </ListItem>
                    <ListItem>
                      <ListItemText>
                        <Typography variant="body1" color="text.secondary"><strong>Descripción: </strong> {bibliografia.descripcion || '-'}</Typography>
                      </ListItemText>
                    </ListItem>
                  </List>
                </Box>
              ));
            } catch (err) {
              return (
                <Typography key={index} color="error">
                  Error al procesar bibliografía {index + 1}
                </Typography>
              );
            }
          });
        } catch (e) {
          return <Typography color="error">No se pudo interpretar la bibliografía.</Typography>;
        }
      })() : (
        <Typography variant="body1" color="text.secondary">
          No hay bibliografías
        </Typography>
      )}
    </AccordionDetails>
  </Accordion>
</Grid>



              <Grid item xs={ 12 }>
                <Accordion>
                  <AccordionSummary
                    expandIcon={ <ExpandMoreIcon /> }
                    aria-controls='goals-content'
                    id='goals-header' sx={{ fontSize: '20px', color: primaryColor, fontWeight: '700' }}
                  >
                    Objetivos
                  </AccordionSummary>
                  <AccordionDetails>
                  { course.OBJETIVOS ?
                    <div dangerouslySetInnerHTML={ { __html: course.OBJETIVOS } } /> :
                      'No hay objetivos' }
                  </AccordionDetails>
                </Accordion>
              </Grid>

              <Grid item xs={ 12 }>
                <Accordion>
                  <AccordionSummary
                    expandIcon={ <ExpandMoreIcon /> }
                    aria-controls='previous-concepts-content'
                    id='previous-concepts-header' sx={{ fontSize: '20px', color: primaryColor, fontWeight: '700' }}
                  >
                    Contenido
                  </AccordionSummary>
                  <AccordionDetails>
                    { course.CONTENIDO && course.CONTENIDO !== '{}' ? (
                      <>
                        { JSON.parse(course.CONTENIDO).map( ( week, index ) => (
                          <Box key={ index }>
                            <Typography variant="h6" component="div"><strong>Semana { index + 1 } </strong></Typography>
                            <List dense={ true } key={ index }>
                              <ListItem>
                                <ListItemText>
                                  <Typography variant="body1" color="text.secondary" component="div"><strong style={ { display: 'block', marginBottom: '16px' } }>Contenido especifico: </strong> <div dangerouslySetInnerHTML={ { __html: week.contenidoEspecifico } } style={ { display: 'block' } } /></Typography>
                                </ListItemText>
                              </ListItem>
                              <ListItem>
                                <ListItemText>
                                  <Typography variant="body1" color="text.secondary" component="div">
                                    <strong style={ { display: 'block', marginBottom: '16px' } }>Contenido detallado: </strong> <div dangerouslySetInnerHTML={ { __html: week.contenidoDetallado } } style={ { display: 'block' } } /></Typography>
                                </ListItemText>
                              </ListItem>
                            </List>
                          </Box>
                        ) ) }
                      </>
                    ) : (
                      <Typography variant="body1" color="text.secondary">
                        No hay semanas
                      </Typography>
                    ) }
                  </AccordionDetails>
                </Accordion>
              </Grid>

              <Grid item xs={ 12 }>
                <Accordion>
                  <AccordionSummary
                    expandIcon={ <ExpandMoreIcon /> }
                    aria-controls='teaching-methodology-content'
                    id='teaching-methodology-header' sx={{ fontSize: '20px', color: primaryColor, fontWeight: '700' }}
                  >
                    Metodología de enseñanza
                  </AccordionSummary>
                  <AccordionDetails>
                    { course.METODOLOGIA || course.METODOLOGIA ?
                      <div dangerouslySetInnerHTML={ { __html: course.METODOLOGIA || course.METODOLOGIA } } /> : 'No hay información' }
                  </AccordionDetails>
                </Accordion>
              </Grid>

              <Grid item xs={ 12 }>
                <Accordion>
                  <AccordionSummary
                    expandIcon={ <ExpandMoreIcon /> }
                    aria-controls='requirements-content'
                    id='requirements-header' sx={{ fontSize: '20px', color: primaryColor, fontWeight: '700' }}
                  >
                    Requisitos
                  </AccordionSummary>
                  <AccordionDetails>
                    { course.REQUISITOS && course.REQUISITOS !== '{}' ? (
                      <>
                        { JSON.parse( course.REQUISITOS ).map( ( requirement, index ) => (
                          <Box key={ index }>
                            <Typography variant="h6" component="div"><strong>Requisito { index + 1 } </strong></Typography>
                            <List dense={ true } key={ index }>
                              <ListItem>
                                <ListItemText>
                                  <Typography variant="body1" color="text.secondary"><strong>Codigo de la asignatura: </strong> { requirement.codigo_asignatura ? requirement.codigo_asignatura : '-' }</Typography>
                                </ListItemText>
                              </ListItem>
                              <ListItem>
                                <ListItemText>
                                  <Typography variant="body1" color="text.secondary"><strong>Nombre de la asignatura: </strong> { requirement.nombre_asignatura ? requirement.nombre_asignatura : '-' }</Typography>
                                </ListItemText>
                              </ListItem>
                              <ListItem>
                                <ListItemText>
                                  <Typography variant="body1" color="text.secondary"><strong>Tipo de requisito: </strong> { requirement.tipo_requisito ? requirement.tipo_requisito : '-' }</Typography>
                                </ListItemText>
                              </ListItem>
                            </List>
                          </Box>
                        ) ) }
                      </>
                    ) : (
                      <Typography variant="body1" color="text.secondary">
                        No hay requisitos
                      </Typography>
                    ) }
                  </AccordionDetails>
                </Accordion>
              </Grid>
          </Grid>
        </Box>
      }
    </>
  )
}
